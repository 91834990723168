import { ILineChartValue, ISetPoint, Stepper, TimeSeriesLineChart } from "../../Components";
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "../../Components/Modal";
import { useTranslation } from "react-i18next";
import { ReportTypePicker } from "./ReportTypePicker";
import moment from "moment";
import { AppState, showAppModal, showSnackbar } from "../../AppState";
import { ReportLocationPicker } from "./ReportLocationPicker";
import { IExportPreset, IHistoryReport, ILocation, ISensor } from "../../Managers/Types";
import { ReportSensorPicker } from "./ReportSensorPicker";
import { IReportOptions, ReportOptions } from "./ReportOptions";
import classNames from "classnames";
import { ConfirmModal } from "../ConfirmModal";
import "./DeviceReportModal.scss";
import {
  addPreset,
  doSaveToReportHistory,
  getConverts,
  getDeviceSensorData,
  getIntervalOptions,
  getNameSlug,
  myOrder,
  prepareChartDataSet,
  saveReports,
  updatePreset,
  useDevicesWithProps,
  usePresets,
} from "../../Managers";
import { useFormik } from "formik";
import { IReportExportOptions, ReportExport } from "./ReportExport";
import * as yup from "yup";
import { isUserPermissionAllowed, PermissionEnum, ValidationMessages } from "../../Enums";
import { PointsOfInterestSetup } from "./PointsOfInterestSetup";
import { PresetPicker } from "./PresetPicker";
import { ReportRangePicker } from "./ReportRangePicker";
import { getLocations } from "../../Managers/API";
import { IReportModalStateProps, reportModalCleanup, ReportModalState, ReportMode, updateReportModalState } from "./ReportModalState";
import { CircularProgress, Stack } from "@mui/material";
import { UseQueryResult } from "react-query";

export interface IChartDataProps {
  sensor: ISensor;
  data?: ILineChartValue[];
  setPoints?: ISetPoint[];
}

export interface IDeviceReportModalProps {
  initialTemplateName?: string;
  presets?: UseQueryResult<IExportPreset[], unknown>;
}

const IMAGE_DIMENSIONS = { width: 535, height: 200 };

const PERCENT_STAGE_FAKE = 20;
const PERCENT_STAGE_REQUEST_SENSORS = 40;
const PERCENT_STAGE_RENDER_IMAGES = 40;
const MAX_PERCENT = 99;

export const DeviceReportModal: React.FC<IDeviceReportModalProps> = ({ initialTemplateName = "", presets }) => {
  const [step, setStep] = useState(ReportModalState.step);
  const [mode, setMode] = useState<ReportMode>(ReportModalState.mode);
  const [startDate, setStartDate] = useState<Date>(ReportModalState.startDate);
  const [endDate, setEndDate] = useState<Date>(ReportModalState.endDate);
  const [location, setLocation] = useState<ILocation | undefined>(ReportModalState.location);
  const [selectedSensors, setSelectedSensors] = useState<IChartDataProps[]>(ReportModalState.sensors);
  const [options, setOptions] = useState<IReportOptions>(ReportModalState.options);
  const [images, setImages] = useState<Record<string, string>>({});
  const [graphReadyToExport, setGraphReadyToExport] = useState(false);
  const [renderGraphs, setRenderGraphs] = useState(false);
  const [editPreset, setEditPreset] = useState(ReportModalState.editPreset);
  const [showLoader, setShowLoader] = useState(false);
  const [locations, setLocations] = useState<ILocation[]>(myOrder(AppState.user?.Locations ?? [], "name"));
  const [percentage, setPercentage] = useState(0);
  const [sensorIndexForChartRendering, setSensorIndexForChartRendering] = useState<number>(0);

  const [preset, setPreset] = useState<IExportPreset | undefined>(() => {
    if (initialTemplateName && presets?.status === "success" && Array.isArray(presets?.data)) {
      return presets.data.find((preset: IExportPreset) => preset?.name === initialTemplateName);
    }
    return undefined;
  });

  useEffect(() => {
    if (preset) {
      const DATA_RANGE_STEP = 2;
      setStep(DATA_RANGE_STEP);

      updateReportModalState({
        step: DATA_RANGE_STEP,
        mode,
        startDate,
        endDate,
        location,
        sensors: selectedSensors,
        preset,
        editPreset,
        options,
      } as IReportModalStateProps);
    }
  }, [preset]);

  const updateModalState = () =>
    updateReportModalState({
      step,
      mode,
      startDate,
      endDate,
      location,
      sensors: selectedSensors,
      preset,
      editPreset,
      options,
    } as IReportModalStateProps);

  useEffect(() => () => updateModalState(), [step, mode, startDate, endDate, location, selectedSensors, preset, editPreset, options]);

  const deviceQuery = useDevicesWithProps(["_id", "serial_number", "name", "is_online", "GatewayId", "location_note"], true, true, true);

  useEffect(() => {
    if (mode === ReportMode.ALERTS) {
      setOptions({
        include_alert_history: true,
        include_alert_notes: true,
        include_alert_acknowledgement_notes: true,
        include_alert_protocol: true,
        exclude_sensor_data: true,
      });
    } else {
      setOptions((prev) => ({
        ...prev,
        exclude_sensor_data: false,
      }));
    }
  }, [mode]);

  useEffect(() => {
    if (isUserPermissionAllowed(PermissionEnum.EDIT_LOCATION)) {
      getLocations().then((res) => setLocations(myOrder(res, "name")));
    }
  }, []);

  useEffect(() => {
    if (!preset) {
      return;
    }

    const {
      include_alert_notes,
      include_sensor_data_notes,
      include_alert_protocol,
      include_alert_history,
      include_graph,
      exclude_sensor_data,
    } = preset;

    setOptions({
      include_graph,
      include_alert_notes,
      include_sensor_data_notes,
      include_alert_history,
      include_alert_protocol,
      exclude_sensor_data,
    });
    exportForm.setValues({
      filename: preset.filename,
      fileFormat: preset.fileType ?? "xlsx",
      lotCode: preset.lotCode,
      productCode: preset.productCode,
      average: preset.average?.toString(),
      interval: preset.interval ?? getIntervalOptions(t)[0].value,
      presetName: preset.name,
    });

    setSelectedSensors(
      deviceQuery.data
        ?.map((d) => d.Sensors.map((s) => ({ ...s, Device: d })))
        .flat()
        .filter((s) => preset.sensorIds.some((id) => id === s._id))
        .map((sensor) => ({ sensor })) ?? [],
    );
    setLocation(locations.find((l) => l._id === preset.LocationId));

    setMode(preset.mode ?? ReportMode.ALERTS);
  }, [preset]);

  const { t } = useTranslation(["export", "common"]);

  const validationSchema = yup.object({
    lotCode: yup.string(),
    filename: yup.string(),
    fileFormat: yup.string(),
    interval: yup.number(),
    average: yup.number(),
    saveAsPreset: yup.boolean(),
    presetName: yup
      .string()
      .nullable(true)
      .test("required", t(ValidationMessages.REQUIRED), (value, obj) => (obj.parent.saveAsPreset ? !!value : true)),
  });

  const exportForm = useFormik<IReportExportOptions>({
    initialValues: { fileFormat: "xlsx", interval: getIntervalOptions(t)[0].value },
    validationSchema,
    onSubmit: () => {},
  });

  const steps = useMemo(() => {
    const step = (key: string, labelClass?: string) => ({ key, label: t(`export:steps.${key}`), labelClass });
    const steps = [step("type")];

    if (mode === ReportMode.TEMPLATE || (!editPreset && preset && mode !== ReportMode.POINTS_OF_INTEREST)) {
      steps.push(step("preset"), step("date_range"), step("export"));
      return steps;
    } else if (!editPreset && preset && mode === ReportMode.POINTS_OF_INTEREST) {
      steps.push(step("preset"), step("date_range"), step("points_of_interest", "poi-step-label-class"), step("export"));
      return steps;
    }
    if (editPreset) {
      steps.push(step("preset"));
    }

    steps.push(...["date_range", "location"].map((key) => step(key)));
    steps.push(step("sensors", "two-line-steps-label-class"));

    if (mode !== ReportMode.ALERTS) {
      steps.push(step("optional", "two-line-steps-label-class"));
    }

    if (mode === ReportMode.POINTS_OF_INTEREST) {
      steps.push(step("points_of_interest", "poi-step-label-class"));
    }

    steps.push(step("export"));

    return steps;
  }, [mode, preset, editPreset]);

  const nextStepDisabled = useMemo(() => {
    switch (steps[step].key) {
      case "sensors":
        return !selectedSensors.length;
      case "date_range":
        return mode === ReportMode.POINTS_OF_INTEREST && moment(endDate).diff(startDate, "day") > 1;
      case "location":
        return !location;
      default:
        return false;
    }
  }, [step, startDate, endDate, location, selectedSensors, preset, editPreset]);

  const getStep = () => {
    switch (steps[step].key) {
      case "type":
        return <ReportTypePicker mode={mode} changeType={setMode} />;
      case "date_range":
        return (
          <ReportRangePicker mode={mode} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
        );
      case "location":
        return (
          <ReportLocationPicker
            availableLocations={locations}
            location={location}
            changeLocation={(location) => {
              setLocation(location);
              setSelectedSensors([]);
            }}
          />
        );
      case "sensors":
        return (
          <ReportSensorPicker
            devices={deviceQuery.data ?? []}
            location={location!}
            sensors={selectedSensors}
            changeSensors={setSelectedSensors}
          />
        );
      case "optional":
        return <ReportOptions changeOptions={setOptions} options={options} mode={mode} />;
      case "points_of_interest":
        return (
          <PointsOfInterestSetup
            minDate={startDate}
            maxDate={endDate}
            selectedSensors={selectedSensors}
            saveSelectedSensors={setSelectedSensors}
          />
        );
      case "preset":
        return (
          <PresetPicker
            preset={preset}
            handleSelectPreset={(p, edit) => {
              setPreset(p);
              setEditPreset(edit ?? false);
            }}
          />
        );
      case "export":
        return (
          <ReportExport preset={preset} editPreset={editPreset} formConfig={exportForm} onSubmit={exportForm.handleSubmit} mode={mode} />
        );
    }
  };

  const onCancel = () => {
    showAppModal(
      <ConfirmModal
        onCancel={() => showAppModal(<DeviceReportModal presets={presets} />)}
        header={t("export:cancel_title")}
        confirmText={t("export:cancel_title")}
        onConfirm={() => {
          reportModalCleanup();
          showAppModal(null);
        }}>
        {t("export:cancel_content")}
      </ConfirmModal>,
    );
  };

  const getSensorData = () => {
    if (!selectedSensors) {
      return [] as IChartDataProps[];
    }
    const withImages = options.include_graph && exportForm.values.fileFormat === "pdf";

    const maxPercentPerStage = withImages ? PERCENT_STAGE_REQUEST_SENSORS : PERCENT_STAGE_REQUEST_SENSORS + PERCENT_STAGE_RENDER_IMAGES;

    const promises: Promise<IChartDataProps>[] = [];
    selectedSensors.forEach((sensor) => {
      const device = sensor.sensor.Device;

      const { convertToRh, convertToTemp } = getConverts(device!);

      promises.push(
        getDeviceSensorData(
          sensor.sensor,
          startDate.toISOString(),
          endDate.toISOString(),
          true,
          exportForm.values.average ? `${exportForm.values.average}h` : undefined,
        ).then((r) => {
          const processed = prepareChartDataSet(r, sensor.sensor, sensor.sensor.is_imperial, convertToRh, convertToTemp);
          let newModel = { ...sensor, data: processed } as IChartDataProps;
          setPercentage((x) => x + maxPercentPerStage / selectedSensors.length);
          return newModel;
        }),
      );
    });
    return Promise.all(promises).catch((e) => {
      const errorMessage = t("export:load_error");
      showSnackbar(errorMessage, "error");
      console.log(errorMessage, e);
      return [];
    });
  };

  useEffect(() => {
    if (!graphReadyToExport || step !== steps.length - 1) {
      return;
    }
    doFinishExport(selectedSensors).then();
  }, [graphReadyToExport]);

  const doFinishExport = async (chartsData: IChartDataProps[]) => {
    const promises = [];

    if (exportForm.values.saveAsPreset) {
      promises.push(savePreset());
    }

    promises.push(saveToReportHistory());

    promises.push(
      saveReports(
        exportForm.values,
        mode === ReportMode.ALERTS ? selectedSensors : chartsData.filter((d) => !!d.data),
        startDate,
        endDate,
        t,
        IMAGE_DIMENSIONS,
        options,
        images,
      ),
    );

    setShowLoader(true);

    try {
      await Promise.all(promises);
      setShowLoader(false);
      reportModalCleanup();
    } catch (error) {
      setShowLoader(false);
      console.error("Error in doFinishExport:", error);
    }
  };

  const exportData = async () => {
    const withImages = options.include_graph && exportForm.values.fileFormat === "pdf";

    setShowLoader(true);
    let intervalCounter = 0;
    let interval = setInterval(() => {
      intervalCounter++;

      setPercentage((v) => {
        if (intervalCounter >= PERCENT_STAGE_FAKE) {
          clearInterval(interval);
        }
        return v + 1;
      });
    }, 500);

    let chartsData = selectedSensors;
    if (mode !== ReportMode.ALERTS) {
      chartsData = await getSensorData();
    }

    if (!chartsData) {
      return;
    }

    setSelectedSensors(chartsData);
    if (withImages) {
      setSensorIndexForChartRendering(0);
      setRenderGraphs(true);
    } else {
      await doFinishExport(chartsData);
    }
  };

  const savePreset = async () => {
    const { average, presetName, interval, filename, fileFormat, lotCode, productCode } = exportForm.values;
    const body = {
      _id: preset?._id,
      name: presetName || "",
      UserId: AppState.user?._id!,
      sensorIds: selectedSensors.map((s) => s.sensor._id),
      mode: mode,
      average: Number(average),
      interval,
      filename,
      fileType: fileFormat,
      lotCode,
      productCode,
      LocationId: location?._id,
      ...options,
    };
    return preset?._id ? await updatePreset(body) : await addPreset(body);
  };

  const saveToReportHistory = async () => {
    const { presetName, filename, lotCode, productCode } = exportForm.values;
    const body = {
      _id: -1,
      templateName: presetName || "",
      UserId: AppState.user?._id!,
      name: filename,
      lotCode,
      productCode,
    } as IHistoryReport;
    await doSaveToReportHistory(body);
  };

  const onExportImage = (imgData: any, item: IChartDataProps, percentagePerExportedChart: number) => {
    images[item.sensor._id] = imgData;
    setImages(images);

    setPercentage((x) => Math.min(x + percentagePerExportedChart, MAX_PERCENT));

    if (Object.values(images).length === selectedSensors.length) {
      setGraphReadyToExport(true);
    } else {
      setSensorIndexForChartRendering((prev) => prev + 1);
    }
  };

  const renderGraphsMethod = useMemo(() => {
    const item = selectedSensors?.[sensorIndexForChartRendering];
    if (!renderGraphs || !item) {
      return null;
    }

    const percentagePerExportedChart = PERCENT_STAGE_RENDER_IMAGES / selectedSensors.length;
    return (
      <div
        key={item.sensor._id}
        style={{
          position: "fixed",
          left: -9000 - 400,
        }}>
        <div className="graph-holder">
          <TimeSeriesLineChart
            zoomEnabled={false}
            height={IMAGE_DIMENSIONS.height * 2}
            width={IMAGE_DIMENSIONS.width * 2}
            data={item.data ?? []}
            bindToSuffix={`${item.sensor._id}`}
            setPoints={item.setPoints}
            onExport={(imgData) => onExportImage(imgData, item, percentagePerExportedChart)}
            title={t(`sensor_types:${getNameSlug(item.sensor.Sensor_type.name)}`)}
          />
        </div>
      </div>
    );
  }, [renderGraphs, selectedSensors, sensorIndexForChartRendering]);
  const transparentClass = showLoader ? "transparent" : "";

  return (
    <Modal
      bodyClassName={"device-report-modal-body"}
      className="modal-lg full"
      buttons={
        <div className="report-footer-buttons">
          <div className="report-footer-buttons-main">
            <button
              className={classNames("btn", step === 0 ? "btn-info" : "btn-plain u-text-teal u-mobile-hide")}
              onClick={() => onCancel()}>
              {t("common:close")}
            </button>

            {step > 0 ? (
              <button className="btn btn-info" disabled={showLoader} onClick={() => setStep(step - 1)}>
                {t("common:back")}
              </button>
            ) : null}

            {step < steps.length - 1 ? (
              <button className="btn btn-primary" disabled={showLoader || nextStepDisabled} onClick={() => setStep(step + 1)}>
                {t("common:next")}
              </button>
            ) : null}

            {step === steps.length - 1 ? (
              <button className="btn btn-primary" onClick={() => exportData()} disabled={showLoader}>
                {t("common:save")}
              </button>
            ) : null}
          </div>
          {step > 1 ? (
            <button type="button" className="btn btn-info btn-plain u-text-teal u-mobile-only" onClick={() => onCancel()}>
              {t("common:close")}
            </button>
          ) : null}
        </div>
      }
      title={
        <>
          <span className="pull-left modal-title">{t("export:export_title")}</span>
          <p className="pull-right">
            {t("export:step_of", {
              currentStep: step + 1,
              totalSteps: steps.length,
            })}
          </p>
        </>
      }>
      <Stepper
        activeStep={step}
        className={`device-report-stepper ${transparentClass}`}
        contentClassName={transparentClass}
        orientation="horizontal"
        steps={steps}>
        {getStep()}
      </Stepper>

      {showLoader && (
        <Stack position="absolute" top={0} left={0} width="100%" height="100%" alignItems="center" justifyContent="center">
          <CircularProgress variant="determinate" size="10em" value={percentage} />
        </Stack>
      )}

      {renderGraphsMethod}
    </Modal>
  );
};
